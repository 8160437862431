import React, { useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';
import { SocialIcon } from 'react-social-icons';
import logoType from '../images/logotype.svg';
import SiteContext from '../context/site-context';

import { Layout, SEO } from '../components';

export default function Blog({ data }) {
  const { isSidebarOpen, setSidebarOpen } = React.useContext(SiteContext);
  // Add state for query
  const [query, setQuery] = useState('');

  // Add state for search results
  const [searchResults, setSearchResults] = useState([]);

  // When query changes, update the state
  function handleChange(e) {
    setQuery(e.target.value);
  }

  // Find all the unique post tags
  const uniqueTags = [];

  data.allSanityPost.nodes.forEach((post) => {
    for (let i = 0; i < post.categories.length; i += 1) {
      if (!uniqueTags.includes(post.categories[i].title)) {
        uniqueTags.push(post.categories[i].title);
      }
    }
  });

  const [filter, setFilter] = useState('All');

  function handleFilter(e) {
    setFilter(e.target.value);
  }

  useEffect(() => {
    // Whenever query is not an empty string, update the search results
    if (query !== '') {
      const results = data.allSanityPost.nodes.filter(
        (post) =>
          post.title.toLowerCase().includes(query.toLowerCase()) ||
          post.categories.find((i) =>
            i.title.toLowerCase().includes(query.toLowerCase())
          ) ||
          post._rawBody[0].children[0].text
            .toLowerCase()
            .includes(query.toLowerCase())
      );
      setSearchResults(results);
    }
    // If the user starts typing, and then deletes their query, set it back to an empty array
    else setSearchResults([]);
  }, [query]);

  function getBlogPost(post) {
    return (
      <div
        key={post.id}
        className="transform duration-500 hover:scale-110 p-6 rounded shadow-lg bg-gray-900"
      >
        <Link to={`/blog/posts/${post.slug.current}`}>
          <Image className="w-full h-64" fluid={post.mainImage.asset.fluid} />
        </Link>
        <div className="px-6 py-4">
          <div className="font-display text-2xl mb-2 clamp-2">
            <Link
              style={{ textDecoration: 'none', color: 'white' }} // .no-underline doesn't override properly
              to={`/blog/posts/${post.slug.current}`}
            >
              {post.title}
            </Link>
          </div>
          <p className="clamp-3 text-white">
            {post._rawBody[0].children[0].text}
          </p>
        </div>
        <div className="px-6 py-4">
          {post.categories.map((cat) => (
            <button
              type="button"
              onClick={() => setFilter(cat.title)}
              key={cat.id}
              className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-black mr-2"
            >
              #{cat.title}
            </button>
          ))}
          <p className="pt-2 text-white">{post._createdAt}</p>
        </div>
      </div>
    );
  }

  let posts = query
    ? searchResults.map((post) => getBlogPost(post))
    : data.allSanityPost.nodes.map((post) => getBlogPost(post));

  if (filter !== 'All') {
    posts = query
      ? searchResults
          .filter((post) =>
            post.categories.find((i) => i.title.includes(filter))
          )
          .map((post) => getBlogPost(post))
      : data.allSanityPost.nodes
          .filter((post) =>
            post.categories.find((i) => i.title.includes(filter))
          )
          .map((post) => getBlogPost(post));
  }

  if (!posts.length) {
    posts = (
      <p className="text-white">
        No results found! Please try a different search.
      </p>
    );
  }

  return (
    <Layout>
      <SEO title="Blog" />
      <header className="bg-gradient">
        <div className="flex flex-wrap items-center justify-center w-full px-12 pt-4 pb-24 mx-auto pointer-events-auto max-w-screen-xl md:justify-between">
          <Link to="/">
            <img
              className="h-8 md:h-6"
              src={logoType}
              alt="4x4 Together logo."
            />
          </Link>
          <div className="flex items-center justify-center flex-shrink-0 w-full mt-4 md:w-auto">
            <SocialIcon
              style={{ height: `1.5rem`, width: `1.5rem` }}
              url="https://www.facebook.com/4x4together"
              target="_blank"
              rel="noopener noreferrer"
              bgColor="#fff"
            />
            <SocialIcon
              className="ml-2"
              style={{ height: `1.5rem`, width: `1.5rem` }}
              url="https://www.instagram.com/4x4together"
              target="_blank"
              rel="noopener noreferrer"
              bgColor="#fff"
            />
            <SocialIcon
              className="ml-2"
              style={{ height: `1.5rem`, width: `1.5rem` }}
              url="https://twitter.com/4x4together"
              target="_blank"
              rel="noopener noreferrer"
              bgColor="#fff"
            />
            <SocialIcon
              className="ml-2"
              style={{ height: `1.5rem`, width: `1.5rem` }}
              url="https://www.youtube.com/channel/UCsR03-p26KNsjNDFOeszueg"
              target="_blank"
              rel="noopener noreferrer"
              bgColor="#fff"
            />
            <span className="hidden ml-8 text-sm text-white md:block">
              Get in touch
            </span>
            <button
              onClick={() => {
                setSidebarOpen(!isSidebarOpen);
              }}
              type="button"
              className={`hamburger hamburger--collapse relative z-40 focus:outline-none flex pr-0 ${
                isSidebarOpen ? ' is-active' : ''
              }`}
              aria-controls="menu"
              aria-expanded="false"
              aria-label="Menu"
            >
              <span className="hamburger-box">
                <span className="hamburger-inner" />
              </span>
            </button>
          </div>
        </div>
      </header>
      <article className="w-full px-4 py-16 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <h1 className="text-center text-4xl mb-8 text-white">
          Welcome to our blog
        </h1>
        <div className="text-center relative text-gray-700 mb-12">
          <input
            onChange={handleChange}
            value={query}
            type="search"
            name="search"
            placeholder="Search"
            className="bg-white h-10 px-5 pr-10 rounded-full text-sm focus:outline-none"
          />
          <div className="relative inline-flex mt-6 sm:mt-0">
            <svg
              className="w-2 h-2 absolute top-0 right-0 m-4 pointer-events-none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 412 232"
            >
              <path
                d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z"
                fill="#648299"
                fillRule="nonzero"
              />
            </svg>
            <select
              value={filter}
              onChange={handleFilter}
              className="ml-4 border border-gray-300 rounded-full text-gray-600 h-10 pl-5 pr-10 bg-white hover:border-gray-400 focus:outline-none appearance-none"
            >
              <option value="All">Filter by tag</option>
              {uniqueTags.map((tag) => (
                <option key={nanoid()} value={tag}>
                  {tag}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="w-full prose grid grid-cols-1 gap-12 lg:grid lg:grid-cols-3 lg:gap-12">
          {posts}
        </div>
      </article>
      <footer className="w-full mt-12 bg-black pointer-events-auto">
        <div className="flex-wrap items-center justify-between w-full px-12 py-2 mx-auto font-sans text-xs font-thin text-center text-white uppercase max-w-screen-xl sm:flex">
          <div>
            <span>© {new Date().getFullYear()} 4x4 Together</span>
            <span className="mx-1">|</span>
            <span className="">
              Website by
              <a
                className="inline-flex px-1 font-semibold"
                href="https://phirannodesigns.com.au"
                target="_blank"
                rel="noopener noreferrer"
              >
                Phiranno Designs
              </a>
            </span>
          </div>
        </div>
      </footer>
    </Layout>
  );
}

Blog.propTypes = {
  data: PropTypes.object,
};

export const graphqlQuery = graphql`
  query MyQuery {
    allSanityPost {
      nodes {
        title
        categories {
          title
          id
        }
        id
        _rawBody(resolveReferences: { maxDepth: 50 })
        slug {
          current
        }
        _createdAt(formatString: "MMMM DD, YYYY")
        mainImage {
          asset {
            fluid(maxWidth: 1920) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`;
